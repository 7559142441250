<template>
    <v-container fluid>
        <PageHeaderSlot showBackButton>
            <template slot="top-right-slot" v-if="$validate.DataValid(id)">
                <v-btn text depressed class="primary ml-2" :to="{ name: 'StudentAttendance', params: { id: id } }">
                    上堂紀錄
                </v-btn>
                <template v-if="userType !== 'tutor'">
                    <v-btn v-if="formData.status === 'inactive'" text depressed class="primary ml-2"  @click="handleStudentRemission()">
                        重新入學
                    </v-btn>
                    <v-btn v-else text depressed class="primary ml-2 error" @click="handleStudentQuit()" :loading="$store.getters.isLoading">
                        學生退學
                    </v-btn>
                </template>
            </template>
        </PageHeaderSlot>


        <v-form ref="dataForm" @submit.prevent="">
            <v-card>
                <v-card-text class="px-2">
                    <v-row class="ma-0">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="分校" 
                                placeholder="選擇分校"
                                :fieldValue.sync="formData.school_id" 
                                dense 
                                :options="tutorSchoolOptions"
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col cols="12" sm="6" md="4" lg="3" v-if="$validate.DataValid(id)">
                            <FormInput 
                                label="學生編號" 
                                disabled 
                                :fieldValue.sync="formData.student_id" 
                                dense 
                                readonly
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                label="姓名" 
                                placeholder="姓名"
                                :fieldValue.sync="formData.student_name" 
                                dense 
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                label="英文姓名" 
                                placeholder="英文姓名"
                                :fieldValue.sync="formData.student_eng_name" 
                                dense 
                                isEngNameOnly
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="年級" 
                                placeholder="選擇年級"
                                :fieldValue.sync="formData.grade" 
                                dense 
                                :options="$gradeOptions"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                label="學校" 
                                placeholder="學校"
                                :fieldValue.sync="formData.student_school_name" 
                                dense 
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                type="tel"
                                label="學生電話" 
                                placeholder="學生電話"
                                :fieldValue.sync="formData.phone" 
                                dense 
                                isIntegerOnly
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            >
                                <template slot="append-outer">
                                    <v-btn class="whatsapp my-0" text :href="$validate.DataValid(formData.phone) ? `https://wa.me/${formData.phone}` : ''" target="_blank" min-width="57px" max-width="57px">
                                        <v-icon size="24" color="white">{{ icon.mdiWhatsapp }}</v-icon>
                                    </v-btn>
                                </template>
                            </FormInput>
                        </v-col>
                        <v-col cols="12" md="8" lg="6">
                            <FormInput 
                                label="住宅地址" 
                                placeholder="住宅地址"
                                :fieldValue.sync="formData.address" 
                                dense 
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                    </v-row>

                    <v-row class="ma-0">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormDatePicker
                                label="出生日期"
                                placeholder="出生日期"
                                :dateValue.sync="formData.birth_date"
                                dense
                                :maxDate="birthDateMax"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormDatePicker>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput
                                readonly
                                label="年齡" 
                                placeholder="年齡"
                                :fieldValue.sync="age" 
                                dense
                                isIntegerOnly
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="性別" 
                                placeholder="性別"
                                :fieldValue.sync="formData.gender" 
                                dense 
                                :options="$genderOptions"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect 
                                label="會員等級" 
                                placeholder="選擇會員等級"
                                :fieldValue.sync="formData.member_level" 
                                dense 
                                :options="$memberOptions"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormSelect>
                        </v-col>

                        <v-col cols="12" sm="6" :md="$validate.DataValid(id) ? '5' : '4'" :lg="$validate.DataValid(id) ? '4' : '3'">
                            <FormInput 
                                label="帳戶名稱" 
                                placeholder="帳戶名稱"
                                :fieldValue.sync="formData.username" 
                                dense 
                                required
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            >
                                <template slot="append-outer" v-if="$validate.DataValid(id)">
                                    <v-btn class="primary" depressed text @click="handlePasswordDialogOpen()">重設密碼</v-btn>
                                </template>
                            </FormInput>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                v-if="!$validate.DataValid(id)"
                                label="密碼"
                                type="password"
                                :fieldValue.sync="formData.password" 
                                dense
                                :required="!$validate.DataValid(id)"
                                :customRules="[
                                    v => {
                                        return this.$validate.isValidPassword(v, false)
                                    }
                                ]"
                                showPwView
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text class="pa-0">
                    <v-row class="ma-0 px-2">
                        <v-col cols="12">
                            <h2 class="colorBlack--text">聯絡人</h2>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <v-row class="ma-0 px-2 pt-3">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                label="聯絡人1 姓名" 
                                placeholder="聯絡人1 姓名"
                                :fieldValue.sync="formData.contact_person_1_name" 
                                dense 
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                type="tel"
                                label="聯絡人1 電話" 
                                placeholder="聯絡人1 電話"
                                :fieldValue.sync="formData.contact_person_1_phone" 
                                dense 
                                isIntegerOnly
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            >
                                <template slot="append-outer">
                                    <v-btn class="whatsapp my-0" text :href="$validate.DataValid(formData.contact_person_1_phone) ? `https://wa.me/${formData.contact_person_1_phone}` : ''" target="_blank" min-width="57px" max-width="57px">
                                        <v-icon size="24" color="white">{{ icon.mdiWhatsapp }}</v-icon>
                                    </v-btn>
                                </template>
                            </FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                label="聯絡人2 姓名" 
                                placeholder="聯絡人2 姓名"
                                :fieldValue.sync="formData.contact_person_2_name" 
                                dense 
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                type="tel"
                                label="聯絡人2 電話" 
                                placeholder="聯絡人2 電話"
                                :fieldValue.sync="formData.contact_person_2_phone" 
                                dense 
                                isIntegerOnly
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            >
                                <template slot="append-outer">
                                    <v-btn class="whatsapp my-0" text :href="$validate.DataValid(formData.contact_person_2_phone) ? `https://wa.me/${formData.contact_person_2_phone}` : ''" target="_blank" min-width="57px" max-width="57px">
                                        <v-icon size="24" color="white">{{ icon.mdiWhatsapp }}</v-icon>
                                    </v-btn>
                                </template>
                            </FormInput>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>

        <v-btn class="primary mt-4" text depressed @click="save()" :loading="$store.getters.isLoading">儲存</v-btn>

        <Datatable
            v-if="$validate.DataValid(id)"
            class="mt-6"
            tableName="學業成績"
            :isLoading="scoreTableLoading"
            :tableData="scoreTableData"
            :tableHeaders="performanceTableHeaders"
            disableFooter
            disablePagination
            @edit-clicked="handlePerformanceDialogOpen('edit', $event)"
            @delete-clicked="handleDeletePerformance"
        >
            <template slot="append-toolbar-left">
                <v-btn text depressed class="primary ml-4 my-2" @click.prevent="handlePerformanceDialogOpen('new')">
                    <v-icon size="20" class="mr-2">{{ icon.mdiPlusCircleOutline }}</v-icon>
                    新增成績
                </v-btn>
            </template>
        </Datatable>

        <!-- 重設密碼 Dialog -->
        <FormDialog
            title="重設密碼"
            btnAlign="center"
            ref="passwordFormDialog"
            formRef="passwordForm"
            @submit-clicked="handlePasswordSubmit()"
            v-if="$validate.DataValid(id)"
        >
            <v-row class="ma-0">
                <v-col cols="12">
                    <FormInput 
                        label="新密碼"
                        type="password"
                        :fieldValue.sync="newPassword" 
                        dense
                        required
                        :customRules="[
                            v => {
                                return this.$validate.isValidPassword(v, false)
                            }
                        ]"
                    ></FormInput>
                </v-col>
                <v-col cols="12">
                    <FormInput 
                        label="確認新密碼"
                        type="password"
                        :fieldValue.sync="confirmPassword" 
                        dense
                        required
                        :customRules="[
                            v => {
                                return  !$validate.DataValid(v) || ($validate.DataValid(v) && v === newPassword) || '密碼不符'
                            }
                        ]"
                    ></FormInput>
                </v-col>
            </v-row>
        </FormDialog>

        <!-- 新增成績 Dialog -->
        <FormDialog
            :title="performanceSubmitType === 'edit' ? '修改成績' : '新增成績'"
            :maxWidth="910"
            ref="performanceDialog"
            formRef="performanceForm"
            @submit-clicked="handlePerformanceSubmit()"
        >
            <v-row class="ma-0">
                <v-col cols="12" md="6">
                    <FormSelect 
                        label="學生年級" 
                        placeholder="選擇學生年級"
                        :fieldValue.sync="performanceFormData.grade" 
                        dense 
                        :options="$gradeOptions"
                        required
                    ></FormSelect>
                </v-col>
                <v-col cols="12" md="6">
                    <FormSelect 
                        label="學期" 
                        placeholder="選擇學期"
                        :fieldValue.sync="performanceFormData.semester" 
                        dense 
                        :options="semesterOptions"
                        required
                    ></FormSelect>
                </v-col>
                <v-col cols="12" md="6">
                    <FormSelect 
                        label="科目" 
                        placeholder="選擇科目"
                        :fieldValue.sync="performanceFormData.subject" 
                        dense 
                        :options="$subjectOptions"
                        required
                    ></FormSelect>
                </v-col>
                <v-col cols="12" md="6">
                    <FormInput 
                        label="測驗/考試名稱"
                        placeholder="測驗/考試名稱"
                        :fieldValue.sync="performanceFormData.exam_name" 
                        dense
                        required
                    ></FormInput>
                </v-col>
                <v-col cols="12" md="6">
                    <FormInput 
                        label="成績"
                        placeholder="成績"
                        :fieldValue.sync="performanceFormData.score" 
                        dense
                        required
                    ></FormInput>
                </v-col>
            </v-row>
        </FormDialog>

        <ConfirmDialog ref="confirmDeletePerformanceDialog" enableShowRemindCheckbox></ConfirmDialog>
        <ConfirmDialog ref="confirmStudentQuitDialog" title="確認退學" :isDeleteDialog="false">是否確認要退學？</ConfirmDialog>
        <ConfirmDialog ref="confirmStudentRemissionDialog" title="確認重新入學" :isDeleteDialog="false">是否確認要重新入學？</ConfirmDialog>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiWhatsapp, mdiPlusCircleOutline } from '@mdi/js';
import Datatable from '@/components/Datatable.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import FormInput from '@/components/formField/FormInput.vue';
import FormSelect from '@/components/formField/FormSelect.vue';
import FormDatePicker from '@/components/formField/FormDatePicker.vue';
import FormDialog from '@/components/FormDialog.vue';

export default {
    name: 'StudentDetails',
    components: {
        Datatable,
        ConfirmDialog,
        FormInput,
        FormSelect,
        FormDatePicker,
        FormDialog
    },
    computed: {
        id() {
            const id = parseInt(this.$route.params.id);
            if (!isNaN(id)) {
                return id;
            }

            return null;
        },
        birthDateMax() {
            const todayYear = new Date().getFullYear();
            const maxYear = todayYear - this.minAge;
            return `${maxYear}-12-31`;
        },
        age() {
            if (this.$validate.DataValid(this.formData.birth_date)) {
                const birthYear = parseInt(this.formData.birth_date.substring(0,4));
                return (new Date().getFullYear() - birthYear).toString();
            }

            return '';
        },
        dispatchUpdateOnChange() {
            if (this.$validate.DataValid(this.id)) {
                return true;
            } else {
                return false;
            }
        }
    },
    data: () => ({
        userType: '',
        formData: {
            school_id: '',
            student_id: '',
            student_name: '',
            student_eng_name: '',
            grade: '',
            student_school_name: '',
            phone: '',
            address: '',
            birth_date: '',
            gender: '',
            member_level: 'normal',
            username: '',
            contact_person_1_name: '',
            contact_person_1_phone: '',
            contact_person_2_name: '',
            contact_person_2_phone: '',
            password: '',
            status: 'active',
        },
        tutorSchoolOptions: [],
        newPassword: '',
        confirmPassword: '',


        scoreTableData: [],
        scoreTableLoading: false,
        performanceSubmitType: '', // 'new' | 'edit'
        performanceFormData: {
            grade: '',
            semester: '',
            subject: '',
            exam_name: '',
            score: '',
        },

        // --- Static data
        icon: {
            mdiWhatsapp,
            mdiPlusCircleOutline
        },
        minAge: 4,
        maxAge: 20,
        performanceTableHeaders: [
            { value: 'create_date', text: '建立時間' },
            { value: 'grade', text: '年級' },
            { value: 'semester', text: '學期' },
            { value: 'subject', text: '科目' },
            { value: 'exam_name', text: '測驗/考試名稱' },
            { value: 'score', text: '成績' },
            { value: 'minActionEditAndDelete', text: '' },
        ],
        semesterOptions: [
            { value: '上學期', text: '上學期' },
            { value: '下學期', text: '下學期' },
        ],
    }),
    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getStudentById() {
            this.scoreTableLoading = true;
            this.formData.student_id = this.$formatter.generateId(this.id);

            try {
                const data = await this.$Fetcher.GetStudentById(this.id);
                for(const key in this.formData) {
                    if (key !== 'student_id' && this.$validate.DataValid(data[key])) {
                        this.formData[key] = data[key];
                    }
                }

               await this.getStudentScores();
            } catch {
                this.setDialogMessage({
                    message: 'noData',
                    returnLink: {name: 'StudentList'}
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },

        async getStudentScores() {
            this.scoreTableLoading = true;
            this.scoreTableData = [];
            try {
                const payload = {
                    filter_item: [
                        { key: 'student_id', value: this.id }
                    ]
                };
                const { data } = await this.$Fetcher.GetStudentScores(payload);
                this.scoreTableData = data.map(el => {
                    return {
                        id: el.id,
                        create_date: el.create_date,
                        grade: el.grade,
                        semester: el.semester,
                        subject: el.subject,
                        exam_name: el.exam_name,
                        score: el.score,
                    }
                });
            
            } catch(err) {
                this.scoreTableData = [];
            } finally {
                this.scoreTableLoading = false;
            }
        },

        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);

            const valid = this.$refs['dataForm'].validate();

            if (!valid) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            const payload = {
                school_id: this.formData.school_id,
                student_name: this.formData.student_name,
                student_eng_name: this.formData.student_eng_name,
                grade: this.formData.grade,
                student_school_name: this.formData.student_school_name,
                phone: this.formData.phone,
                address: this.formData.address,
                birth_date: this.formData.birth_date,
                gender: this.formData.gender,
                member_level: this.formData.member_level,
                username: this.formData.username,
                password: this.formData.password,
                contact_person_1_name: this.formData.contact_person_1_name,
                contact_person_1_phone: this.formData.contact_person_1_phone,
                contact_person_2_name: this.formData.contact_person_2_name,
                contact_person_2_phone: this.formData.contact_person_2_phone,
            };

            if (this.$validate.DataValid(this.id)) {
                try {
                    await this.$Fetcher.UpdateStudent(this.id, payload);

                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '儲存成功',
                        type: 'success',
                        refresh: true,
                        redirect: ''
                    });
                    this.$store.dispatch('setDataIsUpdated', false);
                } catch(err) {
                    this.setDialogMessage({
                        title: "儲存失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            } else {
                payload.password = this.formData.password;

                try {
                    await this.$Fetcher.NewStudent(payload);
                    this.$store.dispatch('setDataIsUpdated', false);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '新增成功',
                        type: 'success',
                        refresh: false,
                        redirect: 'StudentList'
                    });
                   
                } catch (err) {
                    this.setDialogMessage({
                        title: "新增失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },

        // ======== 分校 Options =======
        async getAllSchoolOptions() {
            try {
                const { data } = await this.$Fetcher.GetSchools();
                const filtered = data.filter(el => el.active === true);
                    if (this.$validate.DataValid(filtered)) {
                        this.tutorSchoolOptions = filtered.map(el => {
                        return {
                            value: el.id,
                            text: el.name
                        }
                    })
                }
            } catch {}
        },

        // ======== 學生退學/重新入學 =======
        async handleStudentQuit() {
            const confirm = await this.$refs.confirmStudentQuitDialog.show();
            if (confirm) {
                this.$store.dispatch('setLoading', true);
                try {
                    await this.$Fetcher.StudentQuit(this.id);
                    this.formData.status = 'inactive';
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '成功退學',
                        type: 'success',
                        refresh: false,
                        redirect: ''
                    });
                } catch (err) {
                    this.setDialogMessage({
                        title: "退學失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },

        async handleStudentRemission() {
            const confirm = await this.$refs.confirmStudentRemissionDialog.show();
            if (confirm) {
                this.$store.dispatch('setLoading', true);
                try {
                    await this.$Fetcher.StudentRemission(this.id);
                    this.formData.status = 'active';
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '成功重新入學',
                        type: 'success',
                        refresh: false,
                        redirect: ''
                    });
                } catch (err) {
                    this.setDialogMessage({
                        title: "重新入學失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },

        // ======== 重設密碼 =======
        async handlePasswordDialogOpen() {
            this.newPassword = '';
            this.confirmPassword = '';
            await this.$refs['passwordFormDialog'].$refs['passwordForm'].reset();
            this.$refs['passwordFormDialog'].show();
        },

        async handlePasswordSubmit() {
            try {
                await this.$Fetcher.StudentChangePassword(this.id, this.newPassword);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '更改成功',
                    type: 'success',
                    refresh: false
                });
                this.$refs['passwordFormDialog'].hide();
            } catch(err) {
                this.setDialogMessage({
                    title: "更改失敗",
                    message: err,
                    isError: true,
                    returnLink: null,
                });
                this.setShowDialog(true);
                this.$refs['passwordFormDialog'].setLoading(false);
            }
        },
  

        // ======== 新增成績 =======
        async handlePerformanceDialogOpen(type, id) {
            this.performanceSubmitType = type;

            // -- reset form
            this.performanceFormData.grade = '';
            this.performanceFormData.semester = '';
            this.performanceFormData.subject = '';
            this.performanceFormData.exam_name = '';
            this.performanceFormData.score = '';
            await this.$refs['performanceDialog'].$refs['performanceForm'].reset();

            if (type === 'edit' && this.$validate.DataValid(id)) {
                this.$refs['performanceDialog'].setEditId(id);

                const target = this.scoreTableData.find(el => el.id === id);
                if (target) {
                    this.performanceFormData.grade = target.grade;
                    this.performanceFormData.semester = target.semester;
                    this.performanceFormData.subject = target.subject;
                    this.performanceFormData.exam_name = target.exam_name;
                    this.performanceFormData.score = target.score;
                }
            }

            if (type === 'new' || type === 'edit' && this.$validate.DataValid(id)) {
                this.$refs['performanceDialog'].show();
            }
        },

        async handlePerformanceSubmit() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }
          

            let payload = {
                student_id: this.id,
                exam_name: this.performanceFormData.exam_name,
                grade: this.performanceFormData.grade,
                semester: this.performanceFormData.semester,
                subject: this.performanceFormData.subject,
                score: this.performanceFormData.score,
            }

            if (this.performanceSubmitType === 'new') {
                try {
                    await this.$Fetcher.NewStudentScore(payload);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '新增成功',
                        type: 'success',
                        refresh: false
                    });

                    this.$refs['performanceDialog'].hide();
                    this.getStudentScores();
                } catch (err) {
                    this.setDialogMessage({
                        title: "新增失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                    this.$refs['passwordFormDialog'].setLoading(false);
                }

            } else if (this.performanceSubmitType === 'edit') {
                const editId = this.$refs['performanceDialog'].getEditId();
                try {
                    await this.$Fetcher.UpdateStudentScore(editId, payload);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '修改成功',
                        type: 'success',
                        refresh: false,
                        redirect: ''
                    });

                    this.$refs['performanceDialog'].hide();
                    this.getStudentScores();
                } catch (err) {
                    this.setDialogMessage({
                        title: "修改失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                    this.$refs['performanceDialog'].setLoading(false);
                }
            }           
        },

        async handleDeletePerformance(id) {
            const confirm = await this.$refs.confirmDeletePerformanceDialog.show();
            if (confirm) {
                const targetPos = this.scoreTableData.findIndex(el => el.id === id);
                if (targetPos > -1) {
                    try {
                        await this.$Fetcher.DeleteStudentScore(id);
                        this.$store.dispatch('toggleAlertMessage', {
                            show: true,
                            message: '刪除成功',
                            type: 'success',
                            refresh: false,
                            redirect: ''
                        });
                        this.scoreTableData.splice(targetPos, 1);
                    } catch (err) {
                        this.setDialogMessage({
                            title: "刪除失敗",
                            message: err,
                            isError: true,
                            returnLink: null,
                        });
                        this.setShowDialog(true);
                    }
                }
            }
        },
    },
    async mounted() {
        await this.getAllSchoolOptions();
    },

    async created() {
        this.$store.dispatch('toggleLoadingPage', true);
        const check = await this.checkUserData();
        if (check) {
            this.userType = await this.getUserType();
            if (this.$validate.DataValid(this.id)) { // edit
                this.getStudentById();
            } else { // new
                const selectedSchool = await this.getUserSelectedSchool();
                if (this.$validate.DataValid(selectedSchool)) {
                    this.formData.school_id = selectedSchool;
                }
                this.$store.dispatch('toggleLoadingPage', false);
            }
        }
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>